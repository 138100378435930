import axios from "axios";
import React, { useEffect, useState } from "react";
// import { Carousel } from "react-responsive-carousel";
import Tbg from "../images/testimonial-bg.avif";

const Testimonial = () => {
  const [testimonials, setTestimonial] = useState([]);
  const options = {
    items: 1,
    loop: true,
    margin: 10,
    nav: true,
    navText: [
      '<span class="prev-arrow">❮</span>',
      '<span class="next-arrow">❯</span>',
    ],
  };

  let getAllTestimonial = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}getAllTestimonial`)
      .then(function (response) {
        if (response.data.success) {
          setTestimonial(response.data.data);
          console.log("setTestimonial", response.data.data);
        }
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  };

  useEffect(() => {
    getAllTestimonial();
  }, []);

  return (
 <>
 
 </>
  );
};
export default Testimonial;
