import axios from 'axios';
import React, { useContext, useEffect } from 'react';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
// import {orderContext} from "./User/Order"


function MyVerticallyCenteredModal({show , onHide , item}) {
  // const state=useContext(orderContext);

    const [image , setImage]=useState("");
  const [file, setFile] = useState('');
  const [name , setName]=useState("");
  const [review , setReview]=useState("");
  const [product_id , setProduct_id]=useState();
  const BASE_URL=process.env.REACT_APP_BASE_URL;

  const addImage = (e) => {
    setFile(e.target.files);
    // Check if files are selected
    if (e.target.files && e.target.files.length > 0) {
      const newImageSrc = URL.createObjectURL(e.target.files[0]);
      setImage((e) => [newImageSrc]);

    }
  }

  const [rating, setRating] = useState(0);

  const handleStarClick = (selectedRating) => {
    setRating(selectedRating === rating ? 0 : selectedRating);
    console.log(selectedRating);
  };



  const addReview=(id)=>{
    let api="addTestimonial";
    const token = localStorage.getItem("token");

    const fd=new FormData();
    // fd.append("name",name);
    fd.append("image",file[0]);
    fd.append("description",review);
    if(id){
api="updateTestimonial"
   
    }

    axios
    .post(`${BASE_URL}${api}/${id}`,fd,{
        headers: {
            "Content-Type": "Application/json",
            "x-access-token": `${token}`,
            "version":"1.0.0"
          }
    }).then((response)=>{
        if(response.data.success){
            toast.success(response.data.message);
        }
    }).catch((error)=>{
        console.log(error.message);
    })
  }

  const getTestimonial=()=>{
    const token= localStorage.getItem("token");
    axios
    .get(`${BASE_URL}`)
  }


  useEffect(()=>{
console.log(rating);
console.log("key==>=====>",product_id);

  },[])

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Review Product
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
         
          <div className='row border-bottom align-items-center pb-2'>
            <div className='col-3 col-lg-2'>
              <img src='https://www.redwolf.in/image/cache/catalog/mens-t-shirts/dragon-ball-z-goku-king-kai-kanji-t-shirt-model-1-600x800.jpg?m=1689737282' className='w-100 rounded-1' style={{ aspectRatio: 4 / 4, objectFit: "cover", objectPosition: "top" }} />
            </div>
            <div className='col-9 col-lg-10 lh-sm'>
              <p className='small fw-semibold'>Eminem Slim Shady Oversized T-shirt</p>
              {/* <p>STARs</p> */}
              <div className='d-flex gap-1 fs-5' style={{ color: "rgb(235, 189, 23)" }}>
                {[1, 2, 3, 4, 5].map((index) => (
                  <span
                    key={index}
                    onClick={() => handleStarClick(index)}
                    style={{ cursor: 'pointer' }}
                  >
                    {index <= rating ? (
                      <i className="fa fa-star" aria-hidden="true"></i>
                    ) : (
                      <i className="fa fa-star-o" aria-hidden="true"></i>
                    )}
                  </span>
                ))}
              </div>
            </div>

          </div>



          <div>
            <div className='mt-3'><p>Write a Review</p></div>
            <div>
              <textarea name="textarea" style={{ width: "100%" }} placeholder='Write a review' className='p-2 rounded-1' value={review} onChange={(e)=>setReview(e.target.value)}>

              </textarea>
            </div>
          </div>
          {/* <button onClick={()=>console.log(state)}>click</button> */}

          <div className='my-3 border-bottom pb-3 '>
            {/* <div>
              <p className='text-center '>Add Photo</p>
            </div> */}
            <div>

              <div className='d-flex gap-5 align-items-center justify-content-lg-start justify-content-center'>
                <label for="Add" className='btn btn-outline-dark'><i className="fa fa-camera me-2" aria-hidden="true"></i>Add Image</label>
                <input type="file" className="form-control border-0 border-bottom border-1 border-secondary d-none" id="Add" onChange={addImage} />
                <img src={image} className='rounded-1 col-lg-2 col-3 img-fluid ' style={{ aspectRatio: 4 / 4, objectFit: "cover" }} />
              </div>

            </div>

          </div>

        </div>
      </Modal.Body>
      <Modal.Footer className='text-center m-auto'>
        <Button 
        // onClick={props.onHide} 
        className='btn border-0 bg-dark text-white' onClick={()=>addReview()}>Sumbit</Button>
      </Modal.Footer>
    </Modal>
  );
}




export default MyVerticallyCenteredModal;
