import { useState, useEffect } from "react";
import axios from "axios";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
// import ProductCard from "../../Components/ProductCardnew";
import ProductBreadcrumb from "../../Components/productBreadcrumb";
import RecordNotFound from "../../Components/RecordNotFound";
import Layout from "../../Components/Layout";
// import ProductCard from "../../Components/ProductCard";
import ProductCard1 from "../../Components/ProductCard1";
import MultiRangeSlider from "multi-range-slider-react";
import { Button, Offcanvas } from "react-bootstrap";
// import { useHistory } from "react-router-dom";


const SearchProduct = () => {
  const [HomeCategory, sethomeCategory] = useState([]);
  const [category, setCategory] = useState([]);
  const [subCategory, setSubcategory] = useState([]);
  const [seachedtext, setSearchedText] = useState("");
  const [searchProductData, setSearchProductData] = useState([]);
  // const [product, setProduct] = useState([]);
  const navigate = useNavigate();
  // const history = useHistory();

  const getHomeCategory = () => {

    axios
      .get(`${process.env.REACT_APP_BASE_URL}getAllHomeCategory`, {
        headers: {
          "Content-Type": "Application/json"
        }
      })
      .then(function (response) {
        // handle success
        if (response.data.success == false) {

        } else {
          sethomeCategory(response.data.data);
        }

      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  }

  let Category = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}getAllCategory`)
      .then(function (response) {
        // handle success
        // if (mounted) {
        if (response.data.success) {
          setCategory(response.data.data);
          console.log("search category", response.data.data);

        }
        // }

        // bindCategory();
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  };


  const getsubCategory = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}getAllSubCategory`, {
        headers: {
          "Content-Type": "Application/json",
          "version": "1.0.0"
        }
      }).then((response) => {
        if (response.data.success) {
          setSubcategory(response.data.data);
        }
      }).catch((err) => {
        console.log(err.message);
      })
  }


  const search = () => {
    setSearchProductData([]);
    axios
      .post(`${process.env.REACT_APP_BASE_URL}productSearch`, { search: seachedtext }, {
        headers: {
          "Content-Type": "Application/json",
          "version": "1.0.0",

        }
      }).then((response) => {
        if (response.data.success) {
          setSearchProductData(response.data.data);
          console.log("searched data", response.data.data);
        } else {
          setSearchProductData([])
        }
      }).catch((err) => {
        console.log(err);
      })
  }

  useEffect(() => {
    getHomeCategory();
    Category();
    getsubCategory();
  }, [])





  return (
    <>
      {/* <Layout> */}
      <div className="w-100" >
        <div className="row bg-white">
          {/* <div className="col-1">
     
          </div> */}
          <div className='col-12'>
        
            <div className='p-2'>
         
              <div id='search-box'>

                <div id='search-form' >
                  {/* <div className=""> */}
                  <button onClick={() => window.history.back()} style={{ background: "none", border: "none" }}><i className="fa fa-angle-left" style={{ fontSize: "25px" }}></i> </button>
                    <input id='search-text' name='q' placeholder='Search here' type='text' onChange={(e) => setSearchedText(e.target.value)} />
                    <button id='search-button' onClick={() => search()}><span>Search</span></button>
                  {/* </div> */}

                </div></div>

            </div>
        
          </div>

        </div>
        <div className="" style={{ height: "10px", backgroundColor: "rgb(248, 248, 255)" }}></div>
        {
          searchProductData && searchProductData.length > 0 ? <></> : <>



            <div className=" bg-white mt-1 "  >


              <h2 className="px-5 py-3">Popular searches</h2>
              <div className=" d-flex justify-content-around container px-3 py-5" style={{ flexWrap: "wrap" }}>
                {
                  category && category.length > 0 ? category.map((item, index) => (
                    <>
                      <div className="m-3" key={index}>
                        <span className="searchcategoryspan " onClick={() => navigate(`/category/${item.slug}`)}>{item.name}</span>
                      </div>
                      {
                        item.subCategory && item.subCategory.length > 0 ? item.subCategory.map((item1, index1) => (
                          <>
                            <div className="m-3" key={index1}>
                              <span className="searchcategoryspan " onClick={() => navigate(`/category/${item.slug}/${item1.slug}`)}>{item1.name}</span>
                            </div>
                          </>
                        )) : ""
                      }
                    </>
                  )) : ""
                }
                {/* {
   subCategory&&subCategory.length>0?subCategory.map((item,index)=>(
<>
    <div  key={index} className="m-3">
<span className="searchcategoryspan " 
onClick={()=>navigate(`/category/${item.slug}/${item1.slug}`)}
>{item.name}</span>
 </div>
   
   </>
   )):""
 } */}
                {
                  HomeCategory && HomeCategory.length > 0 ? HomeCategory.map((item, index) => (
                    <>
                      <div key={index} className="m-3">
                        <span className="searchcategoryspan " onClick={() => navigate(`/home-category-product/ + ${item.slug}`)}>{item.name}</span>
                      </div>

                    </>
                  )) : ""
                }


              </div>

            </div>
          </>
        }




        <div className="row bg-white w-100">
          {searchProductData && searchProductData.length > 0 ?
            searchProductData.map((item, index) => (
              <ProductCard1 key={index} item={item} />
            )) : (
              <div className="w-100 d-flex justify-content-center align-items-center" style={{ height: "50vh" }}>
                <div className="paraS">
                  No record found
                </div>
              </div>
            )
          }
        </div>

      </div>


      {/* </Layout> */}
    </>
  );
}

export default SearchProduct;