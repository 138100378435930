import { memo } from "react";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";

import axios from "axios";

const Footer = () => {
  const [screenSize, getDimension] = useState({
    dynamicWidth: window.innerWidth,
  });

  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  const initialState = {
    about_us: "",
    email: "",
    mobile_no: "",
  };
  const [metaTag, setMetaTag] = useState(initialState);

  useEffect(() => {
    getWebsiteInfo();
  }, []);

  const getWebsiteInfo = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}getWebsiteInfo`, {
        headers: {
          version: "1.0.0",
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        if (response.data.success === false) {
        } else {
          // console.log(response.data.data);
          setMetaTag({
            about_us: response.data.data.about_us,
            email: response.data.data.email,
            mobile_no: response.data.data.phone_no,
            address: response.data.data.address,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <>
      {/* {(window.innerWidth<700)?<MobileViewFooter/>:<DesktopViewFooter/>} */}

      <div className=" footer pt-5">
        <div className="container">
          <div className="row " style={{ textTransform: "capitalize" }}>
            <div className="col-12 col-sm-12 col-md-4 col-lg-5 col-xl-4 col-xxl-4  ps-3 pe-3">
              <h4 style={{ color: "#fff" }}>About Us</h4>
              <ul style={{ padding: 0 }}>
                <li style={{ textTransform: "lowercase" }}>
                  <Link
                    style={{ textDecoration: "none", textAlign: "justify" }}
                  >
                    {metaTag.about_us}
                  </Link>
                </li>
              </ul>
            </div>
            <div
              className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4"
              style={{ paddingLeft: "25px" }}
            >
              <div className="links">
                {" "}
                <h4 style={{ color: "#fff" }}>Useful Links</h4>
              </div>
              <div className="">
                <ul style={{ padding: 0 }}>
                  <li>
                    <Link to="/" style={{ textDecoration: "none" }}>
                      Home
                    </Link>{" "}
                  </li>
                  <li>
                    {" "}
                    <Link to="/AboutUs" style={{ textDecoration: "none" }}>
                      About us
                    </Link>
                  </li>
                  {/* <li >
                <Link to='/BulkOrderInquiry'style={{textDecoration:'none'}}>Bulk Order Inquiry</Link>
              </li> */}
                  <li>
                    {/* <Link to='/Categories'style={{textDecoration:'none'}} >Categories</Link> */}
                  </li>
                </ul>
              </div>
            </div>

            <div
              className="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-4 col-xxl-4"
              style={{ paddingLeft: "25px" }}
            >
              <h4 style={{ color: "#fff" }}>Information</h4>
              <ul style={{ padding: 0 }}>
                <li>
                  <Link to="/PrivacyPolicy" style={{ textDecoration: "none" }}>
                    Privacy Policy
                  </Link>{" "}
                </li>
                <li>
                  <Link
                    to="/TermsAndCondition"
                    style={{ textDecoration: "none" }}
                  >
                    Terms &amp; conditions
                  </Link>{" "}
                </li>
                <li>
                  <Link to="/ReturnPolicy" style={{ textDecoration: "none" }}>
                    Return Policy
                  </Link>
                </li>
                <li>
                  <Link
                    to="/shipping-policy"
                    style={{ textDecoration: "none" }}
                  >
                    Shipping Policy
                  </Link>
                </li>
              </ul>
            </div>
            <div
              style={{
                width: "90%",
                height: "2px",
                backgroundColor: "#fff",
                margin: "20px",
              }}
            ></div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-7 col-lg-7 col-xl-5 col-xxl-5">
              <h4 style={{ color: "#fff" }}>Help And Support</h4>
              <ul style={{ padding: 0 }}>
                <li>
                  <Link
                    to={`tel:+91${metaTag.mobile_no}`}
                    style={{ textDecoration: "none" }}
                  >
                    {/* <a href="tel:+919329473133" style={{textDecoration:"none"}}> */}
                    <i className="fa fa-phone" />
                    &nbsp;&nbsp; {metaTag.mobile_no}
                    {/* </a> */}
                  </Link>
                </li>
                <li style={{ textTransform: "none" }}>
                  <Link
                    to={`mailto:${metaTag.email}`}
                    className="p-0"
                    style={{ textDecoration: "none" }}
                  >
                    <i className="fa fa-envelope m-0" />
                    &nbsp; &nbsp;{metaTag.email}
                  </Link>
                </li>
                <li style={{ textTransform: "none" }}>
                  <Link
                    to=""
                    className="p-0"
                    style={{ textDecoration: "none" }}
                  >
                    <i className="fa fa-map-marker m-0" />
                    &nbsp; &nbsp;{metaTag.address}
                  </Link>
                </li>
              </ul>
            </div>

            <div className="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-6 col-xxl-6 d-flex justify-content-center">
              <span className="social-media-icon">
                <i className="fa fa-facebook" aria-hidden="true"></i>
              </span>
              <span className="social-media-icon">
                <i className="fa fa-instagram" aria-hidden="true"></i>
              </span>
              <span className="social-media-icon">
                <i className="fa fa-twitter" aria-hidden="true"></i>
              </span>
              <span className="social-media-icon">
                <i className="fa fa-linkedin" aria-hidden="true"></i>
              </span>
            </div>
            <div className="col-12 text-center">
              <hr />
              <p className="paraS">All Rights Reserved</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default memo(Footer);
