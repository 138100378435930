import { Container } from "react-bootstrap";
import TopBar from "../Components/TopBar";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import { Link } from "react-router-dom";
import Category from "../Components/Category";
const Categories = () => {
console.log("ds")
    return (
        <>
            <TopBar />
            <Header />
          
            <Container className="pt-3 pb-3">

            <Category />
            

            </Container>

            <Footer />
        </>
    );
}
export default Categories;