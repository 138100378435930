import axios from "axios";
import React, { memo, useContext, useEffect, useState } from "react";
import Badge from "react-bootstrap/Badge";
import { Link } from "react-router-dom";
import SearchBar from "./Searchbar";


import {CartContext} from "../App"
// import {cartL} from "../pages/Cart"
// import { cartItm } from "../App";
const TopBar = () => {
  const state=useContext(CartContext);

  // const cartlength=useContext(cartL);
  const [user, setUser] = useState();
  // const [cartCount, setCartCount] = useState(0);
  


  // useEffect(() => {
  //   let user = localStorage.getItem("user");

  //   if (user != null) {
  //     setUser(user);
  //   }
  // }, []);

  useEffect(() => {
    // console.log("cart ...length",cartlength);
    // loadCartList();
 
    let user = localStorage.getItem("user");
    if (user != null) {
      setUser(user);
    }
  }, []);

  useEffect(()=>{
    console.log("state===================cart===================>",state);
    state?.loadCartList();
  },[state])

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     loadCartList();
  //   }, 10000);
  //   return () => clearInterval(interval);
  // }, []);


  // const loadCartList = () => {
  //   let tmpID = localStorage.getItem("tmpID");

  //   let obj = {
  //     tmpUserID: tmpID,
  //   };
  //   axios
  //     .post(`${process.env.REACT_APP_BASE_URL}getAllCart`, obj)
  //     .then(function (response) {
  //       setCartCount(response.data.data.length);
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // };

  const initialState = {
    logo: "",
    announcement: "",
  };
  // useEffect(()=>{
  //   loadCartList();
  // })
  const [metaTag, setMetaTag] = useState(initialState);

  useEffect(() => {
    getWebsiteInfo();
  }, []);

  const getWebsiteInfo = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}getWebsiteInfo`, {
        headers: {
          version: "1.0.0",
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        if (response.data.success === false) {
        } else {
          // console.log(response.data.data);
          setMetaTag({
            logo: response.data.data.logo,
            announcement: response.data.data.announcement,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <>
      <div className=" text-center text-light p-1 " style={{backgroundColor:"#eaba80"}}>
        <p className="mt-2 subheadingS" >{metaTag.announcement}</p>
      </div>
      {/* <div className="container-fluid d-flex justify-content-end align-items-center topbar2 p-2 ">
        <a href="#">
          <i className="fa fa-phone" />
        </a>
        <span className="me-3"> &nbsp;&nbsp; 1234-567-890</span> &nbsp;
        <a href>
          <i className="fa fa-envelope " />
        </a>
        <span className="topbar-email"> &nbsp; &nbsp;abc@gmail.com</span>
      </div> */}
      {/* topbar end */}
      {/* navbar1 start  */}
      {/* <div className="container-fluid">
        <div className="row text-center" >
          <div className=" col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-4 col-xs-4  nav-col1 d-flex justify-content-center align-items-center">
            <Link to="/">
              {" "}
              <img
                src={metaTag.logo}
                alt="photo frame wala logo"
                style={{ width: "90%", height: "auto" }}
              />
            </Link>
           
          </div>
          <div className=" col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-sm-8 col-xs-8 nav-col2 mt-3 d-flex justify-content-center alighn-items-center" style={{zIndex:1}} >
            <form className="d-flex" role="search">
             

              <SearchBar/>
            </form>
          </div>
          <div className="col-12 col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 nav-col3 mt-4 mb-4 d-flex justify-content-center alighn-items-center">
            <div className="row topbar-icon">
              <div className="col text-center align-middle">
                {user ? (
                  <Link to="/my-profile" className="p-3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ width: 30, height: 30 }}
                      viewBox="0 0 448 512"
                    >
                    
                      <path d="M304 128a80 80 0 1 0 -160 0 80 80 0 1 0 160 0zM96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM49.3 464H398.7c-8.9-63.3-63.3-112-129-112H178.3c-65.7 0-120.1 48.7-129 112zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3z" />
                    </svg>
                  
                  </Link>
                ) : (
                  <Link to="/sign-in" className="p-4">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ width: 30, height: 30 }}
                      viewBox="0 0 512 512"
                    >
                      
                      <path d="M217.9 105.9L340.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L217.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1L32 320c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM352 416l64 0c17.7 0 32-14.3 32-32l0-256c0-17.7-14.3-32-32-32l-64 0c-17.7 0-32-14.3-32-32s14.3-32 32-32l64 0c53 0 96 43 96 96l0 256c0 53-43 96-96 96l-64 0c-17.7 0-32-14.3-32-32s14.3-32 32-32z" />
                    </svg>
                    
                  </Link>
                )}

                <Link to="/cart" className="p-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ width: 30, height: 30 }}
                    viewBox="0 0 448 512"
                  >
                   
                    <path d="M160 112c0-35.3 28.7-64 64-64s64 28.7 64 64v48H160V112zm-48 48H48c-26.5 0-48 21.5-48 48V416c0 53 43 96 96 96H352c53 0 96-43 96-96V208c0-26.5-21.5-48-48-48H336V112C336 50.1 285.9 0 224 0S112 50.1 112 112v48zm24 48a24 24 0 1 1 0 48 24 24 0 1 1 0-48zm152 24a24 24 0 1 1 48 0 24 24 0 1 1 -48 0z" />
                  </svg>
                  <Badge
                    bg="light"
                    text="dark"
                    style={{ marginLeft: "-5px", position: "absolute" }}
                  >
                    {state?.cartlen}
                  </Badge>
                 
                </Link>
              </div>
              
            </div>
          </div>
        </div>
      </div> */}
      {/* navbar1 end  */}
    </>
  );
};

export default memo(TopBar);
