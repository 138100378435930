import React, { memo, useEffect, useState } from "react";
// import Carousel from "react-multi-carousel";

import Image from "react-bootstrap/Image";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import PerfectScrollbar from "react-perfect-scrollbar";
import { Link, useNavigate } from "react-router-dom";
// import { LazyLoadImage } from "react-lazy-load-image-component";
import moment from "moment";
// import { ValidEmail, ValidMobileNumber } from "../function/Validation";

import decorationflower from "../images/Screenshot 2024-03-28 222928.png";
import axios from "axios";

// import { toast } from "react-toastify";
import TabsSlider from "../Components/TabsSlider";
import dummyImageUrl from "../images/placeholder.webp";
import Slider3D from "../Components/Slider3D";

// const Testimonial = React.lazy(() => import("../Components/Testimonial"));

const Layout = React.lazy(() => import("../Components/Layout"));
const ProductCard = React.lazy(() => import("../Components/ProductCard"));

const MainSlider = React.lazy(() => import("../Components/MainSlider"));

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 7,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 6,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 5,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 4,
  },
};

const Home = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile_no, setMobile_No] = useState("");

  const handleClose = () => setShow(false);
  const [show, setShow] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    let currentDate = localStorage.getItem("currentDate");
    if (currentDate == null) {
      localStorage.setItem("currentDate", moment().format("DD-MM-YYYY"));
    } else {
      if (currentDate == moment().format("DD-MM-YYYY")) {
        console.log("date is same");
        setShow(false);
      } else {
        console.log("date not same");
        setShow(true);
      }
    }
  }, []);

  const href = window.location.href;
  console.log("href", href);

  const [homeCategory, setHomeCategory] = useState([]);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    getHomeCategory();
    Category();
  }, []);

  let Category = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}getAllCategory`)
      .then(function (response) {
        // handle success
        // if (mounted) {
        if (response.data.success) {
          setCategories(response.data.data);
        }
        // }

        // bindCategory();
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  };
  const getHomeCategory = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}getAllHomeCategory`)
      .then(function (response) {
        if (response.data.success) {
          setHomeCategory(response.data.data);
          console.log("home Category", response.data.data);
        }
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  };

  const initialState = {
    sideBanner1: "",
    sideBanner2: "",
    sideBannerUrl1: "",
    sideBannerUrl2: "",
    footerBanner: "",
    footerBannerUrl: "",
  };
  const [metaTag, setMetaTag] = useState(initialState);

  useEffect(() => {
    getAllBanner();
  }, []);

  const getAllBanner = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}getAllBanner`, {
        headers: {
          version: "1.0.0",
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        if (response.data.success === false) {
        } else {
          console.log(response.data.data);
          setMetaTag(response.data.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleImageError = (event) => {
    event.target.src = dummyImageUrl;
  };

  return (
    <Layout>
      {/* carousel start  */}
      <MainSlider />

      <Slider3D />
      <div class="nine">
        <h1>
          Categories
          <span className="">
            <img
              src={decorationflower}
              className="img-fluid"
              style={{
                width: "200px",
                height: "auto",
                objectFit: "cover",
                zIndex: "-1",
              }}
            />
          </span>
        </h1>
      </div>

      {/* <PerfectScrollbar className="">
        <div className="container-fluid d-flex gap-3">
          {categories.length > 0 &&
            categories.map((item, index) => (
              <div className="col-3" key={index} style={{ width: "25%" }}>
                <Link
                  to={`/category/${item.slug}`}
                  style={{ textDecoration: "none" }}
                >
                  <img
                    src={item.icon}
                    className="w-100"
                    loading="lazy"
                    onError={handleImageError}
                  />
                </Link>
              </div>
            ))}
        </div>
      </PerfectScrollbar> */}

      {/*slider*/}
      <div className="container">
        <Carousel responsive={responsive}>
          {categories.length > 0 &&
            categories.map((item, index) => (
              <div className="col-md-8" key={index}>
                <img
                  src={item.icon}
                  className="rounded-circle"
                  roundedCircle
                  style={{ width: "100%" }}
                />
                <h6 className="px-0" style={{ textAlign: "center" }}>
                  <Link
                    to={`/category/${item.slug}`}
                    style={{ textDecoration: "none", color: "#000" }}
                  >
                    {item.name}
                  </Link>
                </h6>
              </div>
            ))}
        </Carousel>
      </div>
      {/*slider end*/}

      <br />
      {/* <br /> */}
      {/* <TabsSlider /> */}

      <div style={{ backgroundColor: "#efefef" }}>
        {homeCategory.length > 0 &&
          homeCategory.map((item, index) => (
            <div key={index}>
              {item.products.length > 0 ? (
                <div className="container-fluid  p-3 homeCategoryCont bg-white rounded">
                  <div className="row ">
                    <div className="col-12">
                      <div className="nine">
                        <h1 style={{ position: "relative", zIndex: "0" }}>
                          {item.name}
                          <span className="">
                            <img
                              src={decorationflower}
                              className="img-fluid"
                              style={{
                                width: "200px",
                                height: "auto",
                                objectFit: "cover",
                                zIndex: "-1",
                              }}
                            />
                          </span>
                        </h1>
                      </div>
                    </div>
                  </div>
                  <div key={index} style={{ background: "#fff" }}>
                    <div className=" d-none d-xs-none d-sm-none d-md-block col-12 text-end">
                      <Link
                        to={"/home-category-product/" + item.slug}
                        style={{ textDecoration: "none", color: "#000" }}
                      >
                        <span className="titleS">View All</span>
                      </Link>

                      {/* </div> */}
                    </div>

                    <div className="row">
                      {item.products.length > 0 &&
                        item.products
                          .filter((product) => product.status === "Active")
                          .map((item1, index1) => (
                            <ProductCard
                              home={true}
                              key={index1}
                              item={item1}
                            />
                          ))}
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          ))}

        {/* <Testimonial /> */}
        <div>
          <img
            src="https://crunkthread.com/wp-content/uploads/2023/03/FREE.jpg"
            className="img-fluid w-100"
            loading="lazy"
          />
        </div>
      </div>
    </Layout>
  );
};
export default Home;
