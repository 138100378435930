import Barcode from 'react-barcode';
import { Container } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';

const Receipt=()=>{
    const Print=()=>{
        window.print();
        return false;
    }
    return(
        <>
        <Container >
        <div className='p-3' style={{width:"400px",height:"700px",fontSize:"12px"}}>
            <div className="row">
                <div className="col"><Barcode value="barcode-example" /></div>
                <div className="col"></div>
            </div>
            <div>
               <p className='m-1'>Ship To:</p> 
<p className='m-1'>Customer Name:-</p>
<p className='m-1'>Customer Address:-</p>
<p className='m-1'>City:-</p>
<p className='m-1'>Country:-</p>
            </div>
            <div>
                <p className='m-1'>Order Id:-</p>
                <p className='m-1'>Ship Date:-</p>
            </div>
            <hr/>
            <div>
                <div>
                    <p className='m-1'>Ship from: Photoframewala.com</p>
                    <p className='m-1'>Return Address:Aman nagar laxmi ganj lashkar Gwalior</p>
                   
                </div>

                <Table responsive="sm">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Seller</th>

                            <th>GSTIN</th>
                            <th>INVOICE</th>
                            <th>DATE</th>
                            <th >ITEM TYPE</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1</td>
                            <td>frame</td>

                            <td>2</td>
                            <td>300</td>
                            <td> 600</td>
                            <td> 600</td>
                        </tr>
                        

                    </tbody>
                  
                </Table>
                <div className='d-flex justify-content-center align-items-center'>
        <button onClick={Print} className='btn btn-primary m-2' style={{borderRadius:"0",fontSize:"12px"}}>PRINT</button>
       </div>
            </div>
        </div>
        
       
        </Container>
        </>
    );
}
export default Receipt;