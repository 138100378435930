import axios from 'axios';
import React, { useEffect, useState } from 'react'
import ProductCard from '../../Components/ProductCard';
import Carousel from "react-multi-carousel";
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';

export default function RelatedProduct({ CategoryId }) {
    const [relatedProducts, setRelatedProduct] = useState([]);
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const { id } = useParams()
    const getRelatedP = () => {

        // if (CategoryId) {
        axios

            .get(`${BASE_URL}RelatedProducts/6581334ba9947c080707fbc4`, {
                headers: {
                    "Content-Type": "Application/json",
                    "version": "1.0.0"
                }
            }).then((response) => {
                if (response.data.success === false) {
                    // toast.error(response.data.message);
                } else {
                    setRelatedProduct(response.data.data)
                    console.log("related product", response.data.data)

                }
            })
        // }

    }

    useEffect(() => {
        getRelatedP();


    }, [])


    const responsive4 = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 4
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 4
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };
    return (
        <>
            <div className='container mt-5 mb-5'>
                {
                    relatedProducts.length > 0 ? <>
                        <h1>Related Product</h1>
                        <Carousel responsive={responsive4}>
                            {relatedProducts.length > 0 &&
                                relatedProducts.map((item, index) => (
                                    <div>
                                        <ProductCard home={true} key={index} item={item} />
                                    </div>
                                ))}
                        </Carousel>;
                    </> : ""
                }

            </div>


        </>
    )
}
