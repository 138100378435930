import axios from "axios";
import React, { memo, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import dummyImageUrl from "../images/placeholder.webp";

const ProductCard = (props) => {
  console.log("props ==>", props);
  const [whatsappUrl, setWhatsappUrl] = useState(
    `https://wa.me/?text=Hello, See these amazing products. ${window.location.href}`
  );

  const [pID, setPID] = useState();
  const [defaultInfo, setDefaultInfo] = useState([]);

  const [product, setProduct] = useState({});
  const [image, setImage] = useState([]);

  useEffect(() => {
    setProduct(props.item);
    if (props.item?.product_varients) {
      setDefaultInfo(
        props.item?.product_varients.find(
          (variant) => variant.set_default === true
        )
      );
    }
    setImage(props.item.image[0]);
  }, [product]);

  const handleImageError = (event) => {
    event.target.src = dummyImageUrl;
  };

  return (
    <div className={`col-xl-3 col-md-4 col-6 cardd`}>
      <div className="shadow">
        <div className="position-relative overflow-hidden">
          <Link to={`/product-detail/${product.slug}`}>
            <img
              src={image}
              className="img-fluid object-fit-cover img-hover"
              style={{
                width: "100%",
                aspectRatio: "4/4",
                onError: { handleImageError },
                objectPosition: "top",
              }}
              alt="img"
              loading="lazy"
            />
          </Link>

          <Link
            to={whatsappUrl}
            style={{ textDecoration: "none", color: "#000" }}
          >
            {" "}
            <div className="text-center bg-body-secondary rounded-circle position-absolute favrate px-2 py-1">
              <span className="fs-6 fw-semibold">
                {" "}
                <i className="fa fa-share-alt  titleS" aria-hidden="true"></i>
              </span>
            </div>
          </Link>
        </div>
        {/* {console.log(" product.slug==>", product.slug)} */}
        <Link
          to={`/product-detail/${product.slug}`}
          style={{ textDecoration: "none", color: "#000" }}
        >
          <div className="d-flex flex-column text-center align-items-center justify-content-center p-3">
            <div className="mb-2 text-secondary fw-semibold ">
              {product.name}
            </div>
            <div>
              &nbsp;&nbsp;
              {defaultInfo &&
              defaultInfo?.weightnprice &&
              defaultInfo.weightnprice.length > 0 ? (
                <span className="fw-semibold paraS">
                  <i className="fa fa-inr" aria-hidden="true"></i>

                  {defaultInfo.weightnprice[0].price}
                </span>
              ) : (
                ""
              )}
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};
export default memo(ProductCard);
