import { Container } from "react-bootstrap";
import TopBar from "../Components/TopBar";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import { Link } from "react-router-dom";

import React, { useEffect, useState } from "react";
import axios from "axios";
import Layout from "../Components/Layout";

const ShippingPolicy = () => {
  const [pageContent, setPageContent] = useState([]);
  useEffect(() => {
    GetPages();
  }, []);

  let GetPages = () => {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}getPages`, {
        page_slug: "shipping-policy",
      })
      .then(function (response) {
        // handle success
        // if (mounted) {
        if (response.data.success) {
          console.log(response.data.data);
          setPageContent(response.data.data);
        }
        // }

        // bindCategory();
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  };

  const renderHTML = (rawHTML) =>
    React.createElement("div", {
      dangerouslySetInnerHTML: { __html: rawHTML },
    });

  return (
    <>
      <Layout>
        <Container fluid className="p-0">
          {/* Breadcrumb */}
          <div className="div1 ">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: 200,
              }}
            >
              <div className="text-center">
                <h2 style={{ color: "#fff" }}>{pageContent.name}</h2>
                <nav
                  aria-label="breadcrumb "
                  style={{ background: "transparent" }}
                >
                  <ol
                    className="breadcrumb "
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <li
                      className="breadcrumb-item"
                      style={{ listStyleType: "none" }}
                    >
                      <Link to="/" style={{ color: "#fff" }}>
                        Home
                      </Link>
                    </li>
                    &nbsp;
                    <li
                      className="breadcrumb-item active"
                      aria-current="page"
                      style={{ listStyleType: "none", color: "#fff" }}
                    >
                      &nbsp;{pageContent.name}
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </Container>

        <Container className="pt-5">
          <div className="col-inner text-left">
            {renderHTML(pageContent.description)}
          </div>
        </Container>
      </Layout>
    </>
  );
};
export default ShippingPolicy;
