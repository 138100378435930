import React, { isValidElement, memo, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Spinner,Button,Modal } from "react-bootstrap";

import IndianStates from "../data/IndianStates.json";
import axios from "axios";
import { toast } from "react-toastify";
import Layout from "../Components/Layout";
import { ValidEmail, ValidMobileNumber } from "../function/Validation";

const TopBar = React.lazy(() => import("../Components/TopBar"));
const Header = React.lazy(() => import("../Components/Header"));
const Footer = React.lazy(() => import("../Components/Footer"));

const initialState = {
//   firstname: "",
//   lastname: "",
  fullname:"",
  email: "",
  mobile_no: "",
  password: "",
  address: "",
  address2: "",
  country: "",
  state: "",
  city: "",
 Pin_code: "",
 subject:"",
 message:""
  
};
const BulkOrderInquiry = () => {
  const navigate = useNavigate();
  const [values, setValues] = useState(initialState);
  const [states, setStates] = useState([]);
 
  const [loading, setLoding] = useState(false);
  // const [show, setShow]=useState(false);


  // const handleClose=()=>setShow(false);
  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
    // console.log(e.target.name, " ----- ", e.target.value);
  };
  useEffect(() => {
    setStates(IndianStates);
  }, []);


  

  const handleSubmit = () => {
    setLoding(true);
   


  

    if (values.fullname.length <= 0) {
      toast.error("please enter Full Name:");
      setLoding(false);
      return;
    }

   

    if (!ValidEmail(values.email)) {
      toast.error("Enter valid email");
      setLoding(false);
      return;
    }
    if (!ValidMobileNumber(values.mobile_no)) {
      toast.error("Enter valid Mobile number");
      setLoding(false);
      return;
    }

 
    if (values.state.length=="")
    {
      toast.error("state  is  mandatory");
      setLoding(false);
      return;
    }

    // setShow(true);


    // if(values.otp.length>6 || values.otp.length<6)
    // {
    //   toast.error("Enter Correct OTP");
    //   setLoding(false);
    //   return;
    // }
    // else{
    //   setShow(false);
    // }

   

  
    axios
      .post(`${process.env.REACT_APP_BASE_URL}auth/register`, values)
      .then(function (response) {
        if (response.data.success === false) {
          toast.error(response.data.message);
          setLoding(false);
        } else {
          toast.success(response.data.message);
          console.log(response.data.data);

          // localStorage.setItem("user", response.data.data);
          // localStorage.setItem("token", response.data.data.token);
          navigate("/");
          setLoding(false);
        }
      })
      .catch(function (error) {
        console.log(error);
        toast.error(error);
        setLoding(false);
      });
  };
  return (
    <>
      <Layout>
        <div className="container-fluid p-3 container-div">
          {/* <form action> */}
          <div className="container-fluid signup-container">
            <div className="row ">
              <div className="col-0 col-xs-0 col-lg-2 col-md-2 col-xxl-3 col-sm-1 p-0"></div>
              <div className="col details-container p-5">
                <p className="titleS">
                  <strong>Contact Us Regarding Bulk Order Inquiry</strong>{" "}
                </p>
                <div className="row">
                  <div className="col-md-6  col-xs-12 col-sm-12 ">
                    <label className="mt-3 mb-1">Full Name <span style={{color:'red',fontSize:'20px'}}>*</span></label>

                    <input
                      type="text"
                      placeholder="First Name"
                      className="form-control m-1 p-3"
                      onChange={handleChange}
                      name="fullname"
                      value={values.fullname}
                    />
                  </div>
                  {/* <div className="col-md-6 col-xs-12 col-sm-12">
                    <label>Last Name</label>
                    <input
                      type="text"
                      required
                      className="form-control m-1"
                      placeholder="Last Name"
                      onChange={handleChange}
                      name="lastname"
                      value={values.lastname}
                    />
                  </div> */}


                  <div className="col-md-6 col-xs-12 col-sm-12">
                    <label className="mt-3 mb-1">Mobile No. <span style={{color:'red',fontSize:'20px'}}>*</span></label>
                    <input
                      type="text"
                      required
                      id="txtMobileNumber"
                      className="form-control m-1 p-3"
                      placeholder="Mobile No."
                      onChange={handleChange}
                      name="mobile_no"

                      value={values.mobile_no}
                    />
                  </div>
                  <div className="col-md-6 col-xs-12 col-sm-12">
                    <label className="mt-3 mb-1">Email <span style={{color:'red',fontSize:'20px'}}>*</span></label>
                    <input
                      type="email"
                      required
                      className="form-control m-1 p-3"
                      placeholder="Enter Email ID"
                      onChange={handleChange}
                      name="email"
                      value={values.email}
                    />
                  </div>
           
           
                  <div className="col-md-6 col-xs-12 col-sm-12">
                    <label className="mt-3 mb-1">State <span style={{color:'red',fontSize:'20px'}}>*</span></label>
                    <select
                      className="form-control m-1 p-3 "
                      id="state"
                      name="state"
                      required
                      onChange={handleChange}
                    >
                      <option value="">Choose...</option>
                      {states.length > 0 &&
                        states.map((item) => (
                          <option
                            key={item.key}
                            value={item.name}
                            selected={values.state == item.name ? true : false}
                          >
                            {item.name}
                          </option>
                        ))}
                    </select>
                  </div>

                  <div className="col-md-6 col-xs-12 col-sm-12">
                    <label className="mt-3 mb-1">City</label>
                    <input
                      type="text"
                      required
                      className="form-control m-1 p-3"
                      placeholder="Enter City"
                      onChange={handleChange}
                      name="city"
                      value={values.city}
                    />
                  </div>
                 
                  <div className="col-md-6 col-xs-12 col-sm-12">
                    <label className="mt-3 mb-1">Subject</label>
                    <input
                      type="text"
                      required
                      className="form-control m-1 p-3"
                      placeholder="Enter Subject :-"
                      onChange={handleChange}
                      name="subject"
                      value={values.subject}
                    />
                  </div>
                 
                  <div className="col-md-6 col-xs-12 col-sm-12">
                    <label className="mt-3 mb-1">Message</label>
                    <textarea
                  cols={49}
                  rows={2}
                  className="form-control m-1 p-3"
                  placeholder="Type your message here:- "
                  onChange={{handleChange}}
                  name="message"
                  value={values.message}
                  ></textarea>
                  </div>

                </div>

                <div className="d-flex justify-content-center ">

                  {loading ? (
                    <Spinner animation="border" />
                  ) : (
                    <button
                      type="button"
                      className="btn  buttonns  p-2 mt-3 "
                      onClick={handleSubmit}
                      disabled={(loading) ? true : false}
                    >
                      Submit
                    </button>
                  )}
                </div>
                {/* <div className="d-flex justify-content-center ">
                  <p style={{ fontSize: 12 }}>
                    By signing Up you agree to our{" "}
                    <u className="text-danger"> terms of use</u> and
                    <u className="text-danger"> privacy policy</u>{" "}
                  </p>
                </div> */}

                {/* <hr />

                <div className="d-flex align-items-center justify-content-center">
                  <strong>Already have an account? </strong> &nbsp;{" "}
                  <Link
                    to="/sign-in"
                    style={{ textDecoration: "none" }}
                    className="text-danger"
                  >
                    Sign In
                  </Link>{" "}
                </div> */}
              </div>
              <div className="col-0 col-xs-0 col-lg-2 col-md-2 col-xxl-3 col-sm-1"></div>
            </div>
          </div>
          {/* </form> */}
        </div>
       
      </Layout>
    </>
  );
};
export default memo(BulkOrderInquiry);
