import React, { useContext, useEffect } from "react";
import { useState } from "react";
// import "./mobilemenu.css";
import "./mobilemenu.css";
import { WebInfoContext } from "../App";

import Offcanvas from "react-bootstrap/Offcanvas";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
export default function Mobilemenu(props) {
  const websiteInfo = useContext(WebInfoContext);

  //   const [showmenu, setShowmenu] = useState(false);
  const [allCategories, setAllCategories] = useState([]);
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();

  const getAllcategories = () => {
    axios
      .get(`${BASE_URL}getAllCategory`, {
        headers: {
          "Content-Type": "application/json",
          version: "1.0.0",
        },
      })
      .then((response) => {
        if (response.data.success == false) {
          // console.log()
        } else {
          setAllCategories(response.data.data);
          console.log("setAllCategories", response.data.data);
        }
      });
  };

  useEffect(() => {
    getAllcategories();
    websiteInfo?.getWebsiteInfo();
    console.log("websiteInfo", websiteInfo.metaTag);
  }, []);

  const logOut = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    navigate("/login");
  };

  return (
    <Offcanvas
      show={props.show}
      onHide={props.onHide}
      style={{ width: "360px" }}
    >
      <Offcanvas.Header closeButton style={{ backgroundColor: "#fff" }}>
        <Offcanvas.Title>
          <img src={websiteInfo.metaTag?.logo} alt="" width={60} height={60} />
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body style={{ backgroundColor: "#fff" }}>
        <ul className="mobilemenuul p-0 ">
          <li className="p-4 py-2 mb-2">
            <Link to={`/`}>HOME</Link>
          </li>
          <li className="p-4 py-2 mb-2">
            <Link to={`/AboutUs`}>About Us</Link>
          </li>

          {allCategories.length > 0 &&
            allCategories.map((item, index) => (
              <li className="p-1 d-block" key={index}>
                <p id="mobileNavLinks" className="m-0 ms-3 py-2">
                  {" "}
                  <Link
                    to={`/category/${item?.slug}`}
                    className="desktop-item dottedText"
                  >
                    {item?.name}
                  </Link>
                  {item.subCategory.length > 0 ? (
                    <span
                      className="material-symbols-outlined text-end"
                      style={{ float: "right" }}
                    >
                      expand_more
                    </span>
                  ) : (
                    ""
                  )}
                </p>

                <ul className="submobilemenu" style={{ marginLeft: "25px" }}>
                  {item.subCategory.length > 0 &&
                    item.subCategory.map((item1, index) => (
                      <li key={index}>
                        <Link
                          to={`/category/${item?.slug}/${item1?.slug}`}
                          className="p-2"
                          id="subCategoryLink"
                        >
                          {item1.name}
                        </Link>
                        <ul className="">
                          {item1.childCategory?.length > 0 &&
                            item1.childCategory.map((item2, index) => (
                              <li
                                key={index}
                                style={{
                                  padding: "5px",
                                  listStyleType: "circle",
                                }}
                              >
                                <Link
                                  to={`/category/${item.slug}/${item1?.slug}/${item2?.slug}`}
                                  className="p-2 "
                                  style={{
                                    textWrap: "nowrap",
                                    textOverflow: "ellipsis  [..]",
                                  }}
                                  id="subCategoryLink"
                                >
                                  {item2.name}
                                </Link>
                              </li>
                            ))}
                        </ul>
                      </li>
                    ))}
                </ul>
              </li>
            ))}

          {localStorage.getItem("token") && (
            <>
              <li className="p-4 pb-2 pt-0 mb-2 mt-2">
                <Link to={`/orders`}>Orders</Link>
              </li>
              {/* <br/> */}
              <li className="p-4 py-2 mb-2">
                <Link to={`/myprofile`}>My Profile</Link>
              </li>
              {/* <br/> */}
              <li className="p-4 py-2 mb-2">
                <Link to={`/change-password`}>Change Password</Link>
              </li>
              {/* <br/> */}
              <li className="p-4 py-2 mb-2" onClick={() => logOut()}>
                <Link>Logout</Link>
              </li>
            </>
          )}
        </ul>
      </Offcanvas.Body>
    </Offcanvas>
  );
}
