import Breadcrumb from 'react-bootstrap/Breadcrumb';
const ProductBreadcrumb = (props) => {
console.log(props.slubObj2);
    return (
        <>
            <div className='p-1 mt-3 ' style={{ padding: '0', margin: 0 }}>
                <ul className='d-flex'>
                    <li style={{ listStyleType: "none", textDecoration: "none",color:"#1B4242" }}>
                    {props.home}
                    </li>

                    <li style={{ listStyleType: "none", textDecoration: "none", fontSize: '20px',color:"#1B4242"  }}>
                        &nbsp;&nbsp;
                        {props.slugObj1}
                        &nbsp;
                    </li>
                    {
                        (props.slugObj2 !== undefined) ? (
                            <li style={{ listStyleType: "none", textDecoration: "none", fontSize: '20px',color:"#1B4242"  }}>
                                &nbsp;<span className='	fa fa-angle-right' style={{ fontSize: '20px' }}></span>
                                &nbsp;&nbsp;
                                {props.slugObj2}
                            </li>
                        ) : ""
                    }

                </ul>
            </div>
            {/* <hr style={{ color: "lightgrey", padding: '0', margin: 0, boxShadow: 'box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;' }}></hr> */}
        </>
    );
}
export default ProductBreadcrumb;