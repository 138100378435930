import React from 'react'
import { useNavigate } from 'react-router-dom'
import "./profilenav.css";
export default function Profilenav() {
    const navigate= useNavigate();
    const logOut = () => {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        navigate("/sign-in");
      };

      const scrollToBottom = () => {
    //    useEffect(() => {
  window.scrollTo(0, document.body.scrollHeight);
// }, []);
      }
  return (
    <>
    <div className='filterNav'>
<nav className='profilenavv' style={{zIndex:"1",width:"100%!important",backgroundColor:"transparent"}}>
        <label htmlFor="toggleMainNav" className="hamburgermenu" onClick={()=>scrollToBottom()}>

          Menu 
          <span className="material-symbols-outlined">
            expand_more
          </span>
        </label>
        <input type="checkbox" id="toggleMainNav" className="check-toggle" />
        {/* <label for="toggleMainNav" class="menu-overlay"></label> */}
        <div className="main-nav-holder">
          <ul className="profileul main-nav">
            <li className="profLi close-nav">
              <label htmlFor="toggleMainNav"></label>
            </li>
         
           
            <li className="profLi has-submenu">
              <input type="checkbox" id="labelAntioxidants" className="check-toggle" />
              <label htmlFor="labelAntioxidants paraS" onClick={()=>navigate('/change-password')}>Change password</label>
            </li>
        
         
            <li className="profLi has-submenu">
              <input type="checkbox" id="labelMen" className="check-toggle" />
              <label htmlFor="labelMen paraS" onClick={()=>navigate('/my-profile')}>My profile </label>
            </li>
            <li className="profLi has-submenu">
              <input type="checkbox" id="labelWomen" className="check-toggle" />
              <label htmlFor="labelWomen paraS" onClick={()=>navigate('/order')}>Orders</label>
            </li>
            <li className=" profLi has-submenu">
              <input type="checkbox" id="labelbeautyproducts" className="check-toggle" />
              <label htmlFor="labelbeautyproducts paraS" onClick={()=>logOut()}>Logout</label>
            </li>
          </ul>
        </div>


      </nav>
</div>
    </>
  )
}
