import { Container } from "react-bootstrap";
import Table from 'react-bootstrap/Table';

const ViewOrder = () => {

    const Print=()=>{
        window.print();
        return false;
    }

    return (
        <>
        <Container fluid style={{ backgroundColor: "yellow" }}>
            <Container >
                <div className="row">
                    <div className="col">
                        <h3>Invoice</h3>
                    </div>
                    <div className="col-8 text-end">
                        <h4>Photo Frame Wala</h4>
                        <p>Aman nagar ,Laxmi ganj Gwalior (M.P)</p>
                    </div>
                </div>

            </Container>
            </Container>
            <Container>
            <div className="row">
                    <div className="col">
                        <p className="m-1">Bill to:-</p>
                        <p className="m-1">customer name:</p>
                        <p className="m-1">customer address:</p>
                        <p className="m-1">City:</p>
                        <p className="m-1">country:</p>
                    </div>
                    <div className="col text-end">
                        <p className="m-1">Invoic Amount:</p>
                        <p className="m-1">Date:</p>
                    </div>
                </div>
            </Container>
            <Container>
             

                <Table responsive="sm">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Item</th>

                            <th>Quantity</th>
                            <th>Price</th>
                            <th>Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1</td>
                            <td>frame</td>

                            <td>2</td>
                            <td>300</td>
                            <td> 600</td>
                        </tr>
                        <tr>
                            <td>1</td>
                            <td>frame</td>

                            <td>2</td>
                            <td>300</td>
                            <td> 600</td>
                        </tr>

                    </tbody>
                  
                </Table>
                <div className="row">
                    <div className="col" style={{backgroundColor:"#dae7f2"}}></div>
                    <div className="col-6 text-end" style={{backgroundColor:"#765bba",color:"#fff",fontWeight:"bold"}}>
                        <p className="m-1" >Total Amount:-<span>&nbsp;&nbsp;234</span></p>
                        <p className="m-1">Discount:<span>&nbsp;&nbsp;36</span></p>
                        <p className="m-1">Grand Total:-<span>&nbsp;&nbsp;270</span></p>
                    </div>
                   </div>
            </Container>

            <div className="d-flex justify-content-center align-items-center">
                <button className="btn btn-primary m-5" style={{borderRadius:"0"}}
                 onClick={Print}>PRINT</button>
            </div>

        </>
    );
}
export default ViewOrder;