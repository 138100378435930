import React, { memo, useEffect, useState,useContext } from "react";
import axios from "axios";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link } from "react-router-dom";


const Header = () => {

  const [categories, setCategories] = useState([]);

  useEffect(() => {
    let mounted = true;
 

   // setCategories(categoriesData);
    
    axios
      .get(`${process.env.REACT_APP_BASE_URL}getAllCategory`)
      .then(function (response) {
        // handle success
        if(mounted) {
        setCategories(response.data.data);
        }
        
        // bindCategory();
       
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
      return () => mounted = false;
  }, []);

  // const bindCategory = () => {
  //   return categories.map((item) => (
  //     <Nav.Link as={Link} to={item.slug}>
  //       {item.name}
  //     </Nav.Link>
  //   ));
  // };

  return (
    <>
      <Navbar collapseOnSelect expand='xl' bg="dark" variant="dark" className="navbar-top"  style={{position:"sticky",top:"0",zIndex:"99"}}>
        <Container fluid>
          {/* <Navbar.Brand className=".d-lg-none .d-xl-none .d-xxl-none " as={Link} to="/">
            Photo Frame Wala
            </Navbar.Brand> */}
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link as={Link} to="/">
                Home
              </Nav.Link>
              {categories.length > 0 &&
                categories.map((item, index) => {
                  let subCategory = item.subCategory;

                  return (
                    <>
                      {subCategory.length > 0 ? (
                        <NavDropdown
                        key={index}
                          title={item.name}
                          id="collasible-nav-dropdown"
                        >
                          {subCategory.map((item1, index1) => (
                            <NavDropdown.Item key={index1} as={Link} to={`/category/${item.slug}/${item1.slug}`}>
                              {item1.name}
                            </NavDropdown.Item>
                          ))}
                        </NavDropdown>
                      ) : (
                        <Nav.Link
                          key={index}
                          as={Link}
                          to={`/category/${item.slug}`}
                        >
                          {item.name}
                        </Nav.Link>
                      )}
                    </>
                  );
                })}
            </Nav>
            {/* <Nav>
              <Nav.Link href="#deets">Blogs</Nav.Link>
              <Nav.Link eventKey={2} href="#memes">
                Testimonial
              </Nav.Link>
            </Nav> */}
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};
export default memo(Header);
