import React from "react";
import Carousel from "react-spring-3d-carousel";
export default function Slider3D() {
  const slides = [
    {
      key: 1,
      content: <img src="https://picsum.photos/800/800/?random" alt="1" />,
    },
    {
      key: 2,
      content: <img src="https://picsum.photos/800/800/?random" alt="2" />,
    },
    {
      key: 3,
      content: <img src="https://picsum.photos/600/800/?random" alt="3" />,
    },
    {
      key: 4,
      content: <img src="https://picsum.photos/800/500/?random" alt="4" />,
    },
    {
      key: 5,
      content: <img src="https://picsum.photos/800/800/?random" alt="5" />,
    },
    {
      key: 6,
      content: <img src="https://picsum.photos/500/800/?random" alt="6" />,
    },
    {
      key: 7,
      content: <img src="https://picsum.photos/800/600/?random" alt="7" />,
    },
    {
      key: 8,
      content: <img src="https://picsum.photos/800/800/?random" alt="8" />,
    },
  ];
  return (
    <div>
      <Carousel slides={slides} />
    </div>
  );
}
