import { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import ProductBreadcrumb from "../../Components/productBreadcrumb";
import RecordNotFound from "../../Components/RecordNotFound";
import Layout from "../../Components/Layout";
import ProductCard1 from "../../Components/ProductCard1";
import { Button, Offcanvas, Spinner } from "react-bootstrap";

const ChildCategoryProducts = () => {
  const navigate = useNavigate();
  const [minValue, set_minValue] = useState(100);
  const [maxValue, set_maxValue] = useState(500000000000);
  const [showModel, setShowModel] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [pages, setPages] = useState();

  const [productName, setProductName] = useState("");
  const [loading, setLoading] = useState(false);

  const [products, setProducts] = useState([]);
  const { cat_slug, subc_slug, childc_slug } = useParams();
  const getHomeCategory = () => {
    setLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}getProductsBySubAndChildCategorySlug/${currentPage}`,
        {
          categorySlug: cat_slug,
          subCategorySlug: subc_slug,
          childCategorySlug: childc_slug,
          minAmount: minValue,
          maxAmount: maxValue,
          name: productName,
        },
        {
          headers: {
            "Content-Type": "Application/json",
          },
        }
      )
      .then(function (response) {
        // handle success
        if (response.data.success) {
          setLoading(false);
          setPages(response.data.remainingCount);

          setProducts(response.data.data);
          console.log("child products", response.data.data);
        } else {
          setLoading(false);
        }
      })
      .catch(function (error) {
        setLoading(false);
        console.log(error.message);
      });
    // }
  };

  useEffect(() => {
    getHomeCategory();
  }, [subc_slug, childc_slug, pages]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    getHomeCategory();
  };
  window.scrollTo({
    top: 0,
    behavior: "smooth", // Optional: Smooth scrolling animation
  });
  return (
    <Layout>
      <ProductBreadcrumb
        home={
          <span>
            <span
              className="mb-1"
              style={{ fontSize: 20, cursor: "pointer" }}
              onClick={() => navigate("/")}
            >
              Home&nbsp;&nbsp;&nbsp;
            </span>
            <span
              className="	fa fa-angle-right"
              style={{ fontSize: "25px" }}
            ></span>
          </span>
        }
        slugObj1={subc_slug}
        slugObj2={childc_slug}
      />

      <Button
        onClick={() => setShowModel(true)}
        className="btn  "
        style={{ backgroundColor: "#fff", color: "#000", border: "none" }}
      >
        <div className="d-flex justify-content-center align-items-center">
          <span
            className="material-symbols-outlined"
            style={{ color: "#1e3a2e", fontSize: "clamp(1rem,4.5vw,2rem)" }}
          >
            tune
          </span>
          <span style={{ color: "#000", fontSize: "clamp(1rem,3.5vw,1.4rem)" }}>
            Filter
          </span>
        </div>
      </Button>

      <div className="container-fluid">
        <div className="row my-5 w-100">
          {/* new filter  */}

          <Offcanvas show={showModel} onHide={() => setShowModel(false)}>
            <Offcanvas.Header closeButton>
              <Offcanvas.Title className="text-center">
                <h3>FILTER</h3>
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <div className="overflow-hidden fw-semibold">
                <div className="row justify-content-center  ">
                  <div
                    className="col-12 d-flex flex-column align-items-center shadow-subtle mb-5 "
                    style={{}}
                  >
                    <div className="col-11 mb-md-4 mb-3">
                      <label htmlFor="form-select-1" className="mb-2 paraS">
                        search By product name:
                      </label>
                      <input
                        id="form-select-1"
                        type="text"
                        className="form-control  rounded-0"
                        style={{ padding: "10px", borderRadius: "20px" }}
                        onChange={(e) => setProductName(e.target.value)}
                      />
                    </div>

                    <div className="col-11 d-flex mb-2 align-items-center gap-2">
                      <div className="col-5 ">
                        <label htmlFor="form-select-1" className="mb-2 paraS">
                          price
                        </label>
                        <select
                          id="form-select-1"
                          className="form-select rounded-0 p-2"
                          aria-label="Default select example"
                          onChange={(e) => set_minValue(e.target.value)}
                        >
                          {Array.from(
                            { length: 14 },
                            (_, index) => 200 + index * 50
                          ).map((value) => (
                            <option key={value} value={value} className="paraS">
                              {value}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="mt-4 mx-1 text-secondary paraS">to</div>
                      <div className="col-5 ">
                        <label htmlFor="form-select-1" className="mb-2 paraS">
                          price
                        </label>
                        <select
                          id="form-select-1"
                          className="form-select rounded-0 p-2"
                          aria-label="Default select example"
                          onChange={(e) => set_maxValue(e.target.value)}
                        >
                          {Array.from(
                            { length: 14 },
                            (_, index) => 300 + index * 100
                          ).map((value) => (
                            <option key={value} value={value} className="paraS">
                              {value}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-11 mt-3">
                      <button
                        className="btn mt-3 rounded-3 bg-body-secondary paraS "
                        style={{ backgroundColor: "#efefef" }}
                        onClick={() => getHomeCategory()}
                      >
                        Save
                      </button>
                      <button
                        className="btn ms-5 mt-3 rounded-3 bg-body-secondary paraS "
                        style={{ backgroundColor: "#efefef" }}
                        onClick={() => setShowModel(false)}
                      >
                        Clear All
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Offcanvas.Body>
          </Offcanvas>

          {loading && (
            <div className="w-100 h-100 d-flex justify-content-center align-items-center">
              <Spinner animation="border" size="lg" />
            </div>
          )}

          {products && products.length > 0 ? (
            products.map((item, index) => (
              <ProductCard1 key={index} item={item} />
            ))
          ) : (
            <RecordNotFound />
          )}
        </div>

        <nav
          aria-label="Page navigation example d-flex justify-content-center"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <ul className="pagination">
            <li className={`page-item ${currentPage === 1 ? "disabled" : ""} `}>
              <button
                className="page-link"
                aria-label="Previous"
                onClick={() => handlePageChange(currentPage - 1)}
                style={{
                  borderRadius: "0",
                  padding: "20px",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                }}
              >
                <span aria-hidden="true">&laquo;</span>
              </button>
            </li>

            {Array.from({ length: pages }, (_, i) => i + 1).map((page) => (
              <li
                key={page}
                className={`page-item ${page === currentPage ? "active" : ""}`}
              >
                <button
                  className="page-link"
                  onClick={() => handlePageChange(page)}
                  style={{
                    borderRadius: "0",
                    padding: "20px",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    backgroundColor: "#1e3a2e",
                    color: "#fff",
                  }}
                >
                  {page}
                </button>
              </li>
            ))}
            <li class={`page-item ${currentPage === pages ? "disabled" : ""}`}>
              <button
                className="page-link"
                aria-label="Next"
                onClick={() => handlePageChange(currentPage + 1)}
                style={{
                  borderRadius: "0",
                  padding: "20px",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                }}
              >
                <span aria-hidden="true">&raquo;</span>
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </Layout>
  );
};
export default ChildCategoryProducts;
