import React, { createContext, memo, useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import Home from "./Home";
import {CartContext} from "../App"
import '../App.css'

import Navmenu from "../Components/Navmenu";
import Footer from "../Components/Footer";
const ProductsInfo = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const [Item, setItem] = useState(state.product_details);
  const [modalShow, setModalShow] =useState(false);
  const [image , setImage]=useState("");
  const [file, setFile] = useState('');
  const [name , setName]=useState("");
  const [review , setReview]=useState("");
  const [productId , setProductId]=useState("");
  const [id, setId]=useState();
  const [productInfo , setProductInfo]=useState([]);
  console.log(state,"state==============================>")
  const [cartlen, setCartlen] = useState();
  const [quantity, setQuantity] = useState(1);
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const [user, setUser] = useState({});

  const addImage = (e) => {
    setFile(e.target.files);
    // Check if files are selected
    if (e.target.files && e.target.files.length > 0) {
      const newImageSrc = URL.createObjectURL(e.target.files[0]);
      setImage((e) => [newImageSrc]);

    }
  }


  const addReview=()=>{
    const fd=new FormData();
console.log("id",id);
    let api="addTestimonial";
  
    const token = localStorage.getItem("token");

    if(id){
      api="updateTestimonial";
      fd.append("id",id)
    }
    fd.append("productId",productId);
    fd.append("image",file[0]);
    fd.append("description",review);
    fd.append("rate",rating);
    fd.append("name",name);
  

    axios
    .post(`${BASE_URL}${api}`,fd,{
        headers: {
            "Content-Type": "Application/json",
            "x-access-token": `${token}`,
            "version":"1.0.0"
          }
    }).then((response)=>{
        if(response.data.success){
          setId("");
          setModalShow(false)
            toast.success(response.data.message);
        }
    }).catch((error)=>{
        console.log(error.message);
    })
  }
  const getReview=(id)=>{
    let token= localStorage.getItem("token");
    axios
    .get(`${BASE_URL}getTestimonial/${id}`,{
      headers:{
        "x-access-token":`${token}`,
        "Content-Type":"Application/json",
        "version":"1.0.0"
      }
    }).then((response)=>{
      if(response.data.success){
       setImage(response.data.data[0].image);
       console.log("review",response.data.data[0].description);
       setReview(response.data.data[0].description);

       setRating(response.data.data[0].rate);
       console.log("rate",response.data.data[0].rate);
       setId(response.data.data[0]._id)
      }
    })
  }


  const [rating, setRating] = useState(0);

  const handleStarClick = (selectedRating) => {
    setRating(selectedRating);
  };
  const openModel=async(item)=>{
   setProductInfo(item);

    console.log("itemmmmm",item);
    setName(state.customer_name);
    setProductId(item._id);
    setModalShow(true)
    getReview(item._id);
  }
  return (
    <>

      {/* <Layout> */}
      <header className="header">
        {/* <TopBar /> */}
        <Navmenu /> 
      </header>
    
      <div className="container d-flex">
      {Item && Item.length > 0 && (
      
          <div class="product_detail_content">
            <div class="product_detail_main" >
              <div className="basket">
               
                <div className="basket-labels">
                  <ul>
                    <li className="cart-Listt-heading item item-heading fs-4">
                      Item
                    </li>
                   
                    <li className="cart-Listt-heading price fs-4">Price</li>
              
                  </ul>
                </div>

                {Item.length > 0 &&
                  Item.map((item, index) => (
                
                      <div className="basket-product" key={index} >
                        <div className="item">
                          <div className="product-image">
                            <Link to={`/product-detail/${item.slug}`}>
                          
                            <img
                              src={item.image[0]}
                              alt={item.name}
                              className="product-frame img-fluid"
                            />
                              </Link>
                          </div>
                          <div className="product-details">
                            <h2>
                              <strong>{item.name}</strong>{' '}
                            </h2>
                        
                            <p className="fs-6">
                              Size :
                              Product Id - {item.productId}
                            </p>
                          </div>
                        </div>
                        <div className="quantity">
                        

                          <h5 className="text-grey mt-1 mr-1 ml-1fs-4 price w-100 text-dark" >
                         
                         {state.amount
                            }
                        
                          </h5>
                        </div>
                        <div className=" fs-4">
                          {' '}
                         
                        </div>

                        <div className="subtotal fs-4 d-flex justify-content-end" style={{ float:"right" }}>

                    
                          <button
                            className="btn loginbtn "
                            onClick={() => openModel(item)}
                            style={{backgroundColor:"#1e3a2e",color:"#fff"}}
                          >
                           Rate Product                         
                            </button>
                        </div>
                      
                      </div>
                    
                  ))}
              </div>
            </div>
            {/* <div className="product_detail_side" >
            
            </div> */}
          </div>
        
      ) 
    
      }
      </div>

    <div className="d-block">
    <Footer/>

    </div>




    <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Review Product
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="row border-bottom align-items-center pb-2">
             
              <div className="col-12 col-lg-10 lh-sm paraS">
                {productInfo&&
                  productInfo.name &&
                  (
                 
                    <p className="small fw-semibold paraS">
                      { productInfo.name}
                    </p>
                  )}
              
                <div
                  className="d-flex gap-1 fs-5"
                  style={{ color: 'rgb(235, 189, 23)' }}
                >
                  {[1, 2, 3, 4, 5].map((index) => (
                    <span
                      key={index}
                      value={rating}
                      onClick={() => handleStarClick(index)}
                      style={{ cursor: 'pointer' }}
                    >
                      {index <= rating ? (
                        <i className="fa fa-star" aria-hidden="true"></i>
                      ) : (
                        <i className="fa fa-star-o" aria-hidden="true"></i>
                      )}
                    </span>
                  ))}
                </div>
              </div>
            </div>

            <div>
              <div className="mt-3">
                <p className="paraS">Write a Review</p>
              </div>
              <div>
                <textarea
                  name="textarea"
                  style={{ width: '100%' }}
                  placeholder="Write a review"
                  className="p-2 rounded-1"
                  value={review}
                  onChange={(e) => setReview(e.target.value)}
                >
                  {/* {review} */}
                </textarea>
              </div>
            </div>
         
            <div className="my-3 border-bottom pb-3 ">
             
              <div>
               

                <div className="d-flex gap-5 align-items-center justify-content-lg-start justify-content-center">
                  <label for="Add" className="btn btn-outline-dark">
                    <i className="fa fa-camera me-2" aria-hidden="true"></i>Add
                    Image
                  </label>
                  <input
                    type="file"
                    className="form-control border-0 border-bottom border-1 border-secondary d-none"
                    id="Add"
                    onChange={addImage}
                  />
                  <img
                    src={image}
                    className="rounded-1 col-lg-2 col-3 img-fluid "
                    style={{ aspectRatio: 4 / 4, objectFit: 'cover' }}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="text-center m-auto">
          <Button
            // onClick={props.onHide}
            className="btn border-0 bg-dark text-white paraS"
            onClick={() => addReview()}
          >
            Sumbit
          </Button>
        </Modal.Footer>
      </Modal>
      {/* <Footer /> */}
      {/* </Layout> */}
    </>
  );
}
export default ProductsInfo;